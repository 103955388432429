import Nav from 'react-bootstrap/Nav';
import {LinkContainer} from 'react-router-bootstrap'

const navItems = [
    {id: 1, "link": "/", "title": "Home"},
    {id: 2, "link": "/puzzle/1/", "title": "Puzzle 1"},
    {id: 3, "link": "/puzzle/2/", "title": "Puzzle 2"},
    {id: 4, "link": "/puzzle/3/", "title": "Puzzle W"},
];

export default function Header() {
    return (
        <Nav variant="pills p-2 pb-4">
            { navItems.map(data => {
                return (
                    <Nav.Item key={data.id}>
                        <LinkContainer to={data.link}>
                            <Nav.Link eventKey={data.id} >
                                {data.title}
                            </Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                )
            })
            }
    </Nav>
    )
}
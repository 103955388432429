import NotFound from "./pages/NotFound";
import PageContainer from "./pages/PageContainer";
import { useRoutes } from "react-router-dom";
import Home from "./pages/Home";
import Puzzle from './pages/Puzzle'

export default function RoutesConfig() {
    const routes = useRoutes([
        {
            path: "/",
            element: <PageContainer />,
            children: [
                { 
                    index: true, 
                    element: <Home />
                },
                {
                    path: "/puzzle/:puzzleId",
                    element: <Puzzle />
                }
            ]
        },
        {
            path: "*",
            element: <NotFound />
        }
    ]);
    
    return routes;
}


import { useEffect, useState } from "react";

const baseUrl = process.env.REACT_APP_BASE_URL_BACKEND;

export const useGetData = (url) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      await fetch(baseUrl + url)
        .then((response) => {
          if(response.ok) {
            return response.json();
          }
          throw response
        })
        .then((data) => setData(data))
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    }

    fetchData();
  }, [url]);

  return {
    data,
    loading,
    error
  }
}
export default function Home() {
    return (
        <>
            <h1>
              Welcome!
            </h1>
            <p>
              <b>Game Explanation</b><br />
              A Nonogram (aka Griddler, Paint By Numbers, Hanjie or Picross) is a puzzle in which you try to reveal a picture. It’s a logic puzzle with simple rules. You have a grid with squares, and every square should become black or white. Numbers on the side and top of the grid reveal the groups of black squares that should be in every row and column.
            </p>
      </>
    );
}
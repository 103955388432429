import { useState, useEffect } from "react";
import Row from "./Row";

export default function Board({data}) {
    const {stepsTaken, info} = data;
    const {width: numberOfCols, height: numberOfRows} = info;
    const baseArray = new Array(numberOfCols * numberOfRows).fill(-1);

    const [gridData, setGridData] = useState(baseArray);
    const [stepsTakenIndex, setStepsTakenIndex] = useState(0);

    useEffect(() => {
        setStepsTakenIndex(0);
        setGridData(new Array(numberOfCols * numberOfRows).fill(-1));
    },[numberOfCols, numberOfRows])
    
    const maxTimeToComplete = 20000; // milliseconds
    const minMilliSeconds = 100;
    const timeOutMillisecond = (maxTimeToComplete / stepsTaken.length < minMilliSeconds ? maxTimeToComplete / stepsTaken.length : minMilliSeconds)

    const updateState = (indexToUpdate, valueToUpdate) => {
        setGridData((prevGridData) => {
            return prevGridData.map((value, index) => {
                if(index === indexToUpdate) {
                    return valueToUpdate;
                } else {
                    return value;
                }
            })
        });
    }

    useEffect(() => {
        let timeout;
        if(stepsTakenIndex < stepsTaken.length) {
            timeout = setTimeout(() => {
                let index = (stepsTaken[stepsTakenIndex]["y"] * numberOfCols) + stepsTaken[stepsTakenIndex]["x"];
                updateState(index, stepsTaken[stepsTakenIndex]["value"])
                setStepsTakenIndex(stepsTakenIndex + 1)
            }, timeOutMillisecond);
        }

        return () => {
            clearTimeout(timeout);
          };
      }, [stepsTakenIndex, numberOfCols, stepsTaken, timeOutMillisecond]);

    const row = [];
    for(let i=0; i < numberOfRows; i++) {
        row.push(i);
    } 

    const rowsToDisplay = row.map(rowId => {
        const start = rowId * numberOfCols;
        const end = start + numberOfCols; 
        const rowData = gridData.slice(start, end);

        return (
            <Row 
                key={rowId}
                rowId={rowId}
                cols={numberOfCols}
                rowData = {rowData}
            />
        )
    })

    return (
        <div className="board">
            {rowsToDisplay}
        </div>
    )
}
import Board from "./Board/Board"
import { useGetData } from '../common/fetchers'
import Loading from "../common/Loading"
import { useParams } from "react-router-dom";

export default function Puzzle() {
    let { puzzleId } = useParams();
    const url = "/index.php?puzzleId="+puzzleId;
    const {data, loading, error} = useGetData(url);
  
    return (
        <div>
            { loading || error 
                ? <Loading /> 
                : <Board 
                    data={data}
                    key={puzzleId}
                    />
            }
        </div>
    )
}
import Cell from "./Cell"

export default function Row({rowId, cols, rowData}) {
    const col = [];
    for(let i=0; i < cols; i++) {
        col.push(i);
    }

    const colData = col.map(colId => {
        return (
            <Cell 
                key={colId} 
                rowId={rowId} 
                colId={colId}
                value={rowData[colId]} 
            />
        )
    })

    return (
        <div className="board--row">
            {colData}
        </div>
    )
}
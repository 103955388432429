import Header from "./Navigation/Header"
import { Outlet } from "react-router-dom"

export default function Pagecontainer() {
    return (
        <div>
            <Header />
            <section className="ps-2">
                <Outlet />
            </section>            
        </div>
    )
}